import React from "react";

import c from "classnames";
import Image from "next/image";

import { SalesPerson } from "@/salesTypes/salesPersons";

import Styles from "./profilePic.module.scss";

type Props = {
	firstName?: string;
	lastName?: string;
	pic?: string;
	size?: string;
};

export const ProfilePic = ({
	firstName,
	lastName,
	pic,
	size = "normal",
}: Props) => {

	/**
	 * Check pic prop is a valid url, either relative or absolute
	 */
	const isValidUrl = (urlString: string | undefined) => {

		if (!urlString) return false;

		if(urlString.startsWith('/')) return true;

		try {
			new URL(urlString);
			return true;
		} catch (e) {
			return false;
		}
	}

	return (
		<span
			title={firstName + " " + lastName}
			className={c(Styles.profilePic, Styles["size-" + size])}
		>
			{isValidUrl(pic) ? (
				<Image alt="Profile picture" layout="fill" src={pic + "?h=150"} />
			) : (
				(firstName?.[0] || "") + (lastName?.[0] || "")
			)}
		</span>
	);
};
