import create from "zustand";

import { SalesPerson } from "@/salesTypes/salesPersons";

type UserDataStore = {
	salesPersons: SalesPerson[];
	activeUser: number | null;
	setSalesPersons: (newSalesPersons: SalesPerson[]) => void;
	updateSalesPersons: (newSalesPerson: SalesPerson) => void;
	setActiveUser: (id: number) => void;
};

export const useUserDataStore = create<UserDataStore>((set) => ({
	salesPersons: [],
	activeUser: null,
	setSalesPersons: (persons: SalesPerson[]) => set({ salesPersons: persons }),
	updateSalesPersons: (person: SalesPerson) => {
		set((state) => {
			const newSalesPersons = state.salesPersons.filter(pers => pers.id !== person.id);
			return {
				salesPersons: [
					...newSalesPersons,
					person
				]
			};
		});
	},
	setActiveUser: (id: number) => set({ activeUser: id }),
	
}));
